import {
  Customer,
  Option,
  Message,
  Order,
  VSSUser,
  PaymentOverview,
} from 'types';
import { APIS, UPDATE_APIS, DELETE_APIS, OrderProps } from './helpers';

const useEndpoints = ({
  fetchApi,
  updateApi,
  deleteApi,
  fetchLeadApi,
  fetchLeadMgtApi,
  fetchCaseApi,
  fetchNoCache,
}) => {
  const userFetch = async () => {
    return await fetchApi(
      'userFetch',
      "/sap/opu/odata/encpp/DP_CORE_SRV/Users('SY-UNAME')?$expand=UserRoles&$format=json"
    );
  };

  const fetchLeadApiData = async () => {
    return await fetchLeadApi(
      'leadApiFetch',
      '/sap/opu/odata/encpp/DP_CORE_SRV/'
    );
  };

  const fetchRegionsByExternalCode = async (region: string) => {
    return await fetchLeadApi(
      'fetchC4CRegions',
      `/codelist?filter=DataType eq 'APC_S_REGION_CODE' and ExternalCode eq '${region}'`,
      undefined,
      region
    );
  };

  const fetchRegionsByLocalCode = async (region: string) => {
    return await fetchLeadApi(
      'fetchC4CRegions',
      `/codelist?filter=DataType eq 'APC_S_REGION_CODE' and LocalCode eq '${region}'`,
      undefined,
      region
    );
  };

  const fetchLeadMgtData = async (args) => {
    return await fetchLeadMgtApi(
      'fetchLeadMgtData',
      `/getleads?retailer_id=${
        args.retailerId
        // '1003273'
      }&retailer_statuscode=${args?.statuses.join(
        ','
      )}&user_statuscode=Z1,04,05`,
      {},
      args
    );
  };

  const fetchLeadMeta = async () => {
    return await fetchLeadMgtApi('fetchLeadMeta', `/metadata`, {});
  };

  const customersFetch = async (filters) => {
    return await fetchApi(
      'customersFetch',
      '/sap/opu/odata/encpp/DP_PART_SRV/OrderBys' + filters,
      undefined,
      filters
    );
  };

  const customerDetail = async (id) => {
    return await fetchApi(
      'customerDetail',
      "/sap/opu/odata/encpp/DP_PART_SRV/OrderBys('" + id + "')?$format=json",
      undefined,
      id
    );
  };

  const customerCreate = async (data: Customer) => {
    const token = await getToken();
    return await updateApi(
      'customerCreate',
      '/sap/opu/odata/ENCPP/DP_PART_SRV/OrderBys',
      token,
      { method: 'POST', body: JSON.stringify(data) }
    );
  };

  const customerUpdate = async (data: Customer) => {
    const token = await getToken();
    return await updateApi(
      'customerUpdate',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/OrderBys('" + data.Id + "')",
      token,
      { method: 'PATCH', body: JSON.stringify(data) }
    );
  };

  const deleteEntity = async (service: string, entity: string) => {
    const token = await getToken();
    return await deleteApi(
      'deleteEntity',
      '/sap/opu/odata/ENCPP/' + service + '/' + entity,
      token,
      { method: 'DELETE' }
    );
  };

  const messagesFetch = async (filters: string = '') => {
    return await fetchApi(
      'messagesFetch',
      '/sap/opu/odata/encpp/DP_IC_SRV/ICDocuments?$skip=0&$top=100&$orderby=EffectiveDate desc&$select=ObjectKey,TypeDescription,DepartmentDescription,Category1Description,ExpiryDate,Subject,Draft,IsEditable,IsViewed,IsViewed,Draft,IsEditable,Priority,Language,ExpiryDate,EffectiveDate&search=&$inlinecount=allpages&$format=json' +
        filters,
      undefined,
      filters
    );
  };

  const messageFetch = async (filters: string = '') => {
    return await fetchApi(
      'messagesFetch',
      "/sap/opu/odata/encpp/DP_IC_SRV/ICDocuments?$filter=ObjectKey eq '" +
        filters +
        "'",
      undefined,
      filters
    );
  };

  const genericFetch = async (url: string = '') => {
    return await fetchApi('messagesFetch', url);
  };

  const messagePermsFetch = async () => {
    return await fetchApi(
      'messagesFetch',
      "/sap/opu/odata/encpp/DP_IC_SRV/ValueHelps?$skip=0&$top=300&$filter=Name eq 'MSG_PERM'"
    );
  };

  const typesFetch = async () => {
    return await fetchApi(
      'typesFetch',
      '/sap/opu/odata/encpp/DP_IC_SRV/ValueHelps?$skip=0&$top=300&$filter=Name%20eq%20%27TYPE%27&$inlinecount=allpages'
    );
  };

  const departmentsFetch = async () => {
    return await fetchApi(
      'departmentsFetch',
      '/sap/opu/odata/encpp/DP_IC_SRV/ValueHelps?$skip=0&$top=300&$filter=Name%20eq%20%27DEPARTMENT%27&$inlinecount=allpages'
    );
  };

  const priorityFetch = async () => {
    return await fetchApi(
      'priorityFetch',
      '/sap/opu/odata/encpp/DP_IC_SRV/ValueHelps?$skip=0&$top=300&$filter=Name%20eq%20%27PRIORITY%27&$inlinecount=allpages'
    );
  };

  const companyGroupFetch = async () => {
    return await fetchApi(
      'companyGroupFetch',
      '/sap/opu/odata/encpp/DP_IC_SRV/ValueHelps?$skip=0&$top=300&$filter=Name%20eq%20%27COMPANYGROUP%27&$inlinecount=allpages'
    );
  };

  const allQuotationsFetch = async (filters: string = '') => {
    return await fetchApi(
      'allQuotationsFetch',
      '/sap/opu/odata/ENCPP/C_SC_QUOTATION_CDS/xENCPPxc_sc_quotation?' +
        filters,
      undefined,
      filters
    );
  };
  const allOrdersFetch = async (filters: string = '') => {
    return await fetchApi(
      'allOrdersFetch',
      '/sap/opu/odata/ENCPP/C_AGENT_ORDERS_CDS/xENCPPxc_agent_orders?' +
        filters,
      undefined,
      filters
    );
  };

  const dealershipsFetch = async () => {
    return await fetchApi(
      'dealershipsFetch',
      "/sap/opu/odata/encpp/DP_CORE_SRV/Users('SY-UNAME')/Partners?$skip=0&$top=300&$orderby=Name asc&$filter=IsVehicleDealer eq 'X' and IsVehicleBlock ne 'X'"
    );
  };

  // not sure what these do
  const salesStatus = async () => {
    // List of dealers
    return await fetchApi(
      'salesStatus',
      '/sap/opu/odata/ENCPP/DP_PART_SRV/PartnerFunctions?$skip=0&$top=300&$inlinecount=allpages'
    );
  };

  const salesTypes = async () => {
    return await fetchApi(
      'salesTypes',
      '/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$skip=0&$top=300&$filter=FieldName%20eq%20%27T005U-BLAND%27%20or%20FieldName%20eq%20%27CA%27&$inlinecount=allpages'
    );
  };

  const vehicleStatus = async () => {
    return await fetchApi(
      'vehicleStatus',
      '/sap/opu/odata/ENCPP/DP_PART_SRV/SalesReps?$skip=0&$top=300&$filter=CustomerId%20eq%20%276353%27&$inlinecount=allpages'
    );
  };

  const downLoadWithout = async (orderNo: string) => {
    return await fetchApi(
      'downLoadWithout',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/PartOrders('${orderNo}')/FormNp/$value`,
      { method: 'GET' },
      { noCache: true, asText: true, orderNo }
    );
  };

  const downLoadWith = async (orderNo: string) => {
    return await fetchApi(
      'downLoadWith',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/PartOrders('${orderNo}')/Form/$value`,
      { method: 'GET' },
      { noCache: true, asText: true, orderNo }
    );
  };

  const shipToFetch = async (customer: string) => {
    return await fetchApi(
      'shipToFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/PartnerFunctions?$skip=0&$top=300&$filter=CustomerId eq '${customer}' and Function eq 'WE' and Division eq '04'`,
      undefined,
      customer
    );
  };

  const showroomFetch = async (customer: string) => {
    return await fetchApi(
      'showroomFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/PartnerFunctions?$skip=0&$top=300&$filter=CustomerId eq '${customer}' and Function eq 'YY' and Division eq '04'`
    );
  };

  const billToFetch = async (customer: string) => {
    return await fetchApi(
      'billToFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/PartnerFunctions?$skip=0&$top=300&$filter=CustomerId eq '${customer}' and Function eq 'RE' and Division eq '04'`,
      undefined,
      customer
    );
  };

  const payerFetch = async (customer: string) => {
    return await fetchApi(
      'payerToFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/PartnerFunctions?$skip=0&$top=300&$filter=CustomerId eq '${customer}' and Function eq 'RG' and Division eq '04'`,
      undefined,
      customer
    );
  };

  const salesChannelsFetch = async () => {
    return await fetchApi(
      'salesChannelsFetch',
      '/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName%20eq%20%27T151T-KDGRP%27'
    );
  };

  const orderStatusesFetch = async () => {
    return await fetchApi(
      'orderStatusesFetch',
      '/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName%20eq%20%27SC_VORDSTATUS%27'
    );
  };

  const reservationStatusesFetch = async () => {
    return await fetchApi(
      'resStatusFetch',
      '/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName%20eq%20%27SC_RESSTATUS%27'
    );
  };

  const vehiclesFetch = async () => {
    return await fetchApi(
      'vehiclesFetch',
      '/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName%20eq%20%27SC_VEHICLE%27'
    );
  };

  const modelFetch = async (args: OrderProps) => {
    return await fetchApi(
      'modelFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/GetMHPModels?Customer='${args.customer}'&DealerId='${args.dealer}'&PartnerRef='${args?.partner}'&OrderStatus='${args.status}'&ShipTo='${args.shipto}'&BillTo='${args.billto}'&Payer='${args.payer}'&DeliverTo='${args.deliverto}'&ReservationNo='${args?.resno}'&ShortCode='${args?.shortcode}'&SalesChannel='${args.saleschannel}'&GRInfo='${args.grinfo}'`,
      undefined,
      args
    );
  };

  const userDetailsFetch = async (userName: string) => {
    return await fetchApi(
      'userDetailsFetch',
      `/sap/opu/odata/ENCPP/DP_CORE_SRV/Users('${userName}')/BP`,
      undefined,
      userName
    );
  };

  const userDealersFetch = async (userName: string) => {
    return await fetchApi(
      'userDealersFetch',
      `/sap/opu/odata/ENCPP/DP_CORE_SRV/Users('${userName}')/Dealers`,
      undefined,
      userName
    );
  };

  const userContactsFetch = async (userName: string) => {
    return await fetchApi(
      'userContactsFetch',
      `/sap/opu/odata/ENCPP/DP_CORE_SRV/Users('${userName}')/ContactPersons`,
      undefined,
      userName
    );
  };

  const orderFetch = async (orderNo: string) => {
    return await fetchApi(
      'orderFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/PartOrders('${orderNo}')`,
      undefined,
      orderNo
    );
  };

  const orderComment = async (orderNo: string) => {
    return await fetchApi(
      'orderCommentFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/OrderTexts(ObjectId='VBBK',ObjectKey='${orderNo}',TextId='Z006')`,
      undefined,
      orderNo
    );
  };

  const orderItemsFetch = async (orderNo: string) => {
    return await fetchApi(
      'orderItemsFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/PartOrders('${orderNo}')/OrderItems`,
      undefined,
      orderNo
    );
  };

  const notificationsBadge = async () => {
    return await fetchApi(
      'notificationsBadge',
      '/sap/opu/odata4/iwngw/notification/default/iwngw/notification_srv/0001/GetBadgeNumber()'
    );
  };

  const notificationsList = async () => {
    return await fetchApi(
      'notificationsList',
      '/sap/opu/odata4/iwngw/notification/default/iwngw/notification_srv/0001/Notifications?$expand=Actions,NavigationTargetParams&$orderby=CreatedAt%20desc&$filter=IsGroupHeader%20eq%20false&$skip=0&$top=21'
    );
  };

  const paymentsFetch = async (orderNo: string) => {
    return await fetchApi(
      'paymentsFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/Payments('${orderNo}')`
    );
  };

  const paymentTypesFetch = async () => {
    return await fetchApi(
      'paymentTypesFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'SC_PYMNT_TYPE'`
    );
  };

  const productTypesFetch = async (paymentType: string) => {
    return await fetchApi(
      'productTypesFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=Key eq '${paymentType}' and FieldName eq 'SC_PRDCT_TYPE'`
    );
  };

  const decisionStatusesFetch = async () => {
    return await fetchApi(
      'productTypesFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'SC_DCSNTATUS'`
    );
  };

  const financeConfigFetch = async (args: any) => {
    return await fetchApi(
      'financeConfigFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/FinanceDatas(PaymentType='${args.paymentType}',ProductName='${args.productName}')`
    );
  };

  const depositRequiredFetch = async (args: any) => {
    return await fetchApi(
      'depositRequiredFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=Key eq '${args.paymentType}' and FieldName eq 'SC_PROD_NAME_${args.productName}'`
    );
  };

  const notificationsBadgeReset = async () => {
    const token = await getToken();
    return await updateApi(
      'notificationsBadgeReset',
      '/sap/opu/odata4/iwngw/notification/default/iwngw/notification_srv/0001/ResetBadgeNumber',
      token,
      { method: 'POST' }
    );
  };

  const notificationDismiss = async (data: any) => {
    const token = await getToken();
    return await updateApi(
      'notificationDismiss',
      '/sap/opu/odata4/iwngw/notification/default/iwngw/notification_srv/0001/Dismiss',
      token,
      { method: 'POST', body: JSON.stringify(data) }
    );
  };

  const notificationAction = async (data: any) => {
    const token = await getToken();
    return await updateApi(
      'notificationAction',
      '/sap/opu/odata4/iwngw/notification/default/iwngw/notification_srv/0001/ExecuteAction',
      token,
      { method: 'POST', body: JSON.stringify(data) }
    );
  };

  const markMessageReadA = async (data: {
    userId: string;
    messageId: string;
  }) => {
    const token = await getToken();
    return await updateApi(
      'notificationAction',
      '/sap/opu/odata/encpp/DP_ACCESSLOG_SRV/AccessLogs',
      token,
      {
        method: 'POST',
        body: JSON.stringify({
          Timestamp: `/Date(${Date.now()})/`,
          UserID: data.userId,
          Name: `#InformationCenter-lookup&/Detail/${data.messageId}/E`,
          FullPath: `#InformationCenter-lookup&/Detail/${data.messageId}/E`,
        }),
      }
    );
  };

  const markMessageReadB = async (data: { messageId: string }) => {
    const token = await getToken();
    return await updateApi(
      'notificationAction',
      '/sap/opu/odata/encpp/DP_IC_SRV/ICDocumentReadSet',
      token,
      {
        method: 'POST',
        body: JSON.stringify({ ObjectKey: data.messageId, IsRead: true }),
      }
    );
  };

  const createOrder = async (args: OrderProps & { ModelId: string }) => {
    const token = await getToken();
    return await updateApi(
      'createOrder',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/CreateMHPHeader?Customer='${
        args.customer
      }'&DealerId='${args.dealer}'&PartnerRef='${args?.partner}'&OrderStatus='${
        args.status
      }'&ShipTo='${args.shipto}'&BillTo='${args.billto}'&Payer='${
        args.payer
      }'&DeliverTo='${args.deliverto}'&ReservationNo='${
        args?.resno
      }'&ShortCode='${args?.shortcode}'&SalesChannel='${
        args.saleschannel
      }'&GRInfo='${args.grinfo}'&ModelId='${
        args?.ModelId
      }'&Quotation=''&ShowRoom='${args?.ShowRoom || ''}'&SalesType='${
        args?.salestype || 'STOCK'
      }'`,
      //'`,
      token,
      {
        method: 'GET',
      }
    );
  };

  const createQuotation = async (args: OrderProps & { ModelId: string }) => {
    const token = await getToken();

    console.log(args);
    return await updateApi(
      'createQuotation',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/CreateMHPHeader?Customer='${
        args.customer
      }'&DealerId='${args.dealer}'&PartnerRef='${args?.partner}'&OrderStatus='${
        args.status
      }'&ShipTo='${args.shipto}'&BillTo='${args.billto}'&Payer='${
        args.payer
      }'&DeliverTo='${args.deliverto}'&ReservationNo='${
        args?.resno
      }'&ShortCode='${args?.shortcode}'&SalesChannel='${
        args.saleschannel
      }'&GRInfo='${args.grinfo}'&ModelId='${
        args?.ModelId
      }'&Quotation='X'&ShowRoom='${args?.ShowRoom || ''}'&SalesType='${
        args?.salestype || 'CUSTOMER'
      }'`,
      token,
      {
        method: 'GET',
      }
    );
  };

  const finaliseOrder = async (data: {
    orderNo: string;
    salesType: string;
  }) => {
    const token = await getToken();
    return await updateApi(
      'finaliseOrder',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/CreateMHPItem?OrderNumber='${data?.orderNo}'`,
      token,
      {
        method: 'GET',
      }
    );
  };

  const convertQuotation = async (QuotationNo: string) => {
    const token = await getToken();
    return await fetchApi(
      'convertQuotation',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/ConvertQtoO?QuotationNumber='${QuotationNo}'`,
      token,
      {
        method: 'GET',
      }
    );
  };

  const updateOrder = async ({
    orderNo,
    salesType,
  }: {
    orderNo: string;
    salesType?: string;
  }) => {
    const token = await getToken();
    return await updateApi(
      'finaliseOrder',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/UpdateMHPItem?OrderNumber='${orderNo}'&SalesType='${
        salesType || ''
      }'`,
      token,
      {
        method: 'GET',
      }
    );
  };

  const updateOrderHeader = async ({
    orderNo,
    salesType,
    customerId,
    salesChannel,
  }: {
    orderNo: string;
    salesType?: string;
    customerId?: string;
    salesChannel?: string;
  }) => {
    const token = await getToken();
    return await updateApi(
      'updateOrderHeader',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/UpdateMHPHeader?OrderNumber='${orderNo}'&ShortCode=''&SalesType='${salesType}'&Customer='${
        customerId || ''
      }'&SalesChannel='${salesChannel || ''}'`,
      token,
      {
        method: 'GET',
      }
    );
  };

  const updatePaymentOverview = async (args: any) => {
    const token = await getToken();
    return await updateApi(
      'updatePaymentOverview',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/Payments('${args.OrderId}')`,
      token,
      {
        method: 'PATCH',
        body: JSON.stringify(args),
      }
    );
  };

  const createPaymentOverview = async (args: any) => {
    const token = await getToken();
    return await updateApi(
      'createPaymentOverview',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/Payments`,
      token,
      {
        method: 'POST',
        body: JSON.stringify(args),
      }
    );
  };

  const createHandoverOverview = async (args: any) => {
    const token = await getToken();
    return await updateApi(
      'createHandoverOverview',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HandOver`,
      token,
      {
        method: 'POST',
        body: JSON.stringify(args),
      }
    );
  };

  const updateHandoverOverview = async (args: any) => {
    const token = await getToken();
    return await updateApi(
      'updateHandoverOverview',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HandOvers('${args.OrderId}')`,
      token,
      {
        method: 'PATCH',
        body: JSON.stringify(args),
      }
    );
  };

  const validatePostalCode = async (args: any) => {
    return await fetchApi(
      'validatePostalCode',
      `/sap/opu/odata/ENCPP/DP_CORE_SRV/ValidatePostalCode?CountryCode='${args.countryCode}'&PostalCode='${args.postCode}'`
    );
  };

  const getLanguagesOptions = async () => {
    return await fetchApi(
      'getLanguageOptions',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'SC_LANGUAGE'"
    );
  };

  const countriesFetch = async () => {
    return await fetchApi(
      'countriesFetch',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'SC_COUNTRY'"
    );
  };

  const handoverFetch = async (orderNo: string) => {
    return await fetchApi(
      'handoverFetch',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HandOvers('${orderNo}')`
    );
  };

  const paymentReceivedFetch = async () => {
    return await fetchApi(
      'paymentReceivedFetch',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'Z_HO_PAYMENT_SDH'"
    );
  };

  const pdiCompleteFetch = async () => {
    return await fetchApi(
      'pdiCompleteFetch',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'Z_HO_PDI_SDH'"
    );
  };

  const remittanceNoticeFetch = async () => {
    return await fetchApi(
      'remittanceNoticeFetch',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'Z_HO_REMIT_NOT_SDH'"
    );
  };

  const regoCompleteFetch = async () => {
    return await fetchApi(
      'regoCompleteFetch',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'Z_HO_REGISTR_SDH'"
    );
  };

  const confirmationFetch = async () => {
    return await fetchApi(
      'confirmationFetch',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'Z_HO_CONFIRMATION_SDH'"
    );
  };

  const readyFetch = async () => {
    return await fetchApi(
      'readyFetch',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'Z_HO_READY_SDH'"
    );
  };

  const sentToFetch = async () => {
    return await fetchApi(
      'sentToFetch',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'Z_HO_DOCUMENT_SDH'"
    );
  };

  const completeFetch = async () => {
    return await fetchApi(
      'completeFetch',
      "/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'Z_HO_COMPLETE_SDH'"
    );
  };

  const getToken = async () => {
    return '';
    const oHeaders = new Headers();
    oHeaders.append('X-CSRF-Token', 'fetch');
    const response = await fetch(
      'process.env.REACT_APP_AGENT_PORTAL_API_URLv1.0/sap/opu/odata/encpp/DP_CORE_SRV/$metadata',
      {
        method: 'GET',
        headers: oHeaders,
      }
    );
    return response.headers.get('x-csrf-token') || '';
  };

  const fetchServiceCategories = async (args) => {
    return await fetchCaseApi(
      'fetchServiceCategories',
      '/servicecategory',
      {},
      args
    );
  };

  const fetchPartsList = async () => {
    return await fetchApi(
      'fetchPartsList',
      '/sap/opu/odata/ENCPP/PART_C_SALESORG_CDS/xENCPPxPART_C_SALESORG'
    );
  };

  const getStatements = async (args: any) => {
    return await fetchApi(
      'getStatements',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/CCMs?$filter=StatementType eq '${args.StatementType}' and Period eq '${args.Period}'`
    );
  };

  const releaseCCM = async (args: any) => {
    return await fetchApi(
      'releaseCCM',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/ReleaseCCM?StatementType='${args.StatementType}'&Period='${args.Period}'&Agree=${args.Agree}&DealerId='${args.DealerId}'`
    );
  };

  const fetchCountry = async (code: string) => {
    return await fetchApi(
      'fetchCountry',
      `/sap/opu/odata/ENCPP/DP_CORE_SRV/Countries('${code}')`,
      undefined,
      { code }
    );
  };

  const fetchUserData = async (customerid: string) => {
    return await fetchApi(
      'fetchUserData',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/BusinessPartners('${customerid}')`,
      undefined,
      { customerid }
    );
  };

  const fetchDemoLogs = async () => {
    return await fetchApi(
      'fetchDemoLogs',
      '/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/DemoLogs'
    );
  };

  const createDemoLog = async (args: any) => {
    const token = await getToken();
    return await updateApi(
      'createDemoLog',
      `/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/DemoLogs`,
      token,
      {
        method: 'POST',
        body: JSON.stringify(args),
      }
    );
  };

  const updateDemoLog = async (args: any) => {
    const token = await getToken();
    return await updateApi(
      'updateDemoLogs',
      `/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/DemoLogs(TestDriveNumber='${args.TestDriveNumber}',VIN='${args.VIN}')`,
      token,
      {
        method: 'PATCH',
        body: JSON.stringify(args),
      }
    );
  };

  const getStatementsByDealer = async (args: any) => {
    return await fetchApi(
      'getStatementsByDealer',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/CCMs?$filter=StatementType eq '${args.StatementType}' and Period eq '${args.Period}' and DealerId eq '${args.DealerId}'`
    );
  };

  const fetchDemoLog = async (args: any) => {
    return await fetchApi(
      'fetchDemoLog',
      `/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/DemoLogs(TestDriveNumber='${args.id}',VIN='${args.vin}')`,
      undefined,
      args
    );
  };

  const fetchDemoLogVINs = async () => {
    return await fetchApi(
      'fetchDemoLogVINs',
      `/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/HelpValues?$filter=FieldName eq 'DEMO_VIN'`
    );
  };

  const fetchDemoLogVIN = async (vin: string) => {
    return await fetchApi(
      'fetchDemoLogVIN',
      `/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/Vehicles?$filter=VIN eq '${vin}'`,
      undefined,
      vin
    );
  };

  const fetchSearchVIN = async (vin: string) => {
    return await fetchNoCache(
      'fetchDemoLogVIN',
      `/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/Vehicles?skip=0&$top=20&$orderby=VIN%20asc&$filter=substringof(%27${vin}%27,VIN)`,
      undefined,
      vin
    );
  };

  const fetchCountries = async () => {
    return await fetchApi(
      'fetchRegions',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'T005T-LAND1'`,
      { method: 'GET' }
    );
  };

  const fetchRegions = async (countrCode: string) => {
    return await fetchApi(
      'fetchRegions',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'T005U-BLAND' or FieldName eq '${countrCode}'`,
      { method: 'GET' },
      countrCode
    );
  };

  const fetchDemoLogTypes = async () => {
    return await fetchApi(
      'fetchDemoLogTypes',
      `/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/HelpValues?$filter=FieldName eq 'ZDBE_VEH_TDRIVE-TDR_TYPE'`
    );
  };

  const fetchDemoLogReasons = async () => {
    return await fetchApi(
      'fetchDemoLogReasons',
      `/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/HelpValues?$filter=FieldName eq 'ZDBE_VEH_TDRIVE-TDR_REASON'`
    );
  };

  const fetchTest1 = async () => {
    return await fetchApi(
      'fetchPartsList',
      "/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/Vehicles?$filter=VIN eq 'SC6GM1AB1PF000008'"
    );
  };

  const fetchTest2 = async () => {
    return await fetchApi(
      'fetchPartsList',
      "/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/HelpValues?$filter=FieldName eq 'DEMO_VIN'"
    );
  };

  const fetchVehicles = async () => {
    return await fetchApi(
      'fetchDemoLogVIN',
      `/sap/opu/odata/ENCPP/DP_VEHICLE_SRV/Vehicles`
    );
  };

  const fetchPartsUnrestricted = async () => {
    return await fetchApi(
      'fetchPartsUnrestricted',
      `/sap/opu/odata/encpp/DP_PART_SRV/PartSearchCDSSet`
    );
  };

  const fetchCCMDealers = async () => {
    return await fetchApi(
      'fetchCCMDealers',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/HelpValues?$filter=FieldName eq 'CCM_DEALER'`
    );
  };

  //https://my358015.crm.ondemand.com/sap/c4c/odata/v1/c4codataapi/CodeListMappingCollection?$filter=DataType eq 'APC_S_REGION_CODE' and ExternalCode eq 'MN'
  const generateContract = async (OrderId: number) => {
    const token = await getToken();
    return await updateApi(
      'generateContract',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/GenerateContract?SalesOrderNumber='${OrderId}'`,
      token,
      { method: 'POST' }
    );
  };

  const fetchRegionMapping = async () => {
    return await fetchApi(
      'fetchRegionMapping',
      `/sap/c4c/odata/v1/c4codataapi/CodeListMappingCollection?$filter=DataType eq 'APC_S_REGION_CODE' and ExternalCode eq 'MN'`
    );
  };

  const updateOrderStatus = async (OrderId: number) => {
    const token = await getToken();
    return await updateApi(
      'generateContract',
      `/sap/opu/odata/ENCPP/DP_PART_SRV/UpdateOrderStatus?SalesOrderNumber='${OrderId}'`,
      token,
      { method: 'POST' }
    );
  };

  const _apis: APIS = {
    allOrdersFetch,
    allQuotationsFetch,
    billToFetch,
    companyGroupFetch,
    customerDetail,
    customersFetch,
    dealershipsFetch,
    departmentsFetch,
    downLoadWith,
    downLoadWithout,
    genericFetch,
    messageFetch,
    messagePermsFetch,
    messagesFetch,
    modelFetch,
    notificationsBadge,
    notificationsList,
    orderFetch,
    orderItemsFetch,
    orderStatusesFetch,
    payerFetch,
    priorityFetch,
    reservationStatusesFetch,
    salesChannelsFetch,
    salesStatus,
    salesTypes,
    shipToFetch,
    showroomFetch,
    typesFetch,
    userDetailsFetch,
    userFetch,
    vehicleStatus,
    vehiclesFetch,
    orderComment,
    finaliseOrder,
    convertQuotation,
    updateOrder,
    validatePostalCode,
    fetchLeadApiData,
    getLanguagesOptions,
    countriesFetch,
    fetchLeadMgtData,
    userDealersFetch,
    paymentsFetch,
    paymentTypesFetch,
    productTypesFetch,
    decisionStatusesFetch,
    financeConfigFetch,
    depositRequiredFetch,
    fetchServiceCategories,
    fetchDemoLogs,
    fetchDemoLog,
    fetchDemoLogVINs,
    fetchDemoLogVIN,
    fetchDemoLogTypes,
    fetchDemoLogReasons,
    fetchTest1,
    fetchTest2,
    fetchPartsList,
    // validateVinNumber,
    handoverFetch,
    paymentReceivedFetch,
    pdiCompleteFetch,
    remittanceNoticeFetch,
    regoCompleteFetch,
    confirmationFetch,
    readyFetch,
    sentToFetch,
    completeFetch,
    getStatements,
    getStatementsByDealer,
    releaseCCM,
    fetchCountry,
    fetchVehicles,
    fetchPartsUnrestricted,
    fetchCCMDealers,
    updateOrderHeader,
    fetchSearchVIN,
    fetchRegions,
    fetchCountries,
    fetchRegionMapping,
    fetchRegionsByExternalCode,
    fetchRegionsByLocalCode,
    fetchUserData,
    userContactsFetch,
    fetchLeadMeta,
  };

  const _updateAPIs: UPDATE_APIS = {
    customerCreate,
    customerUpdate,
    notificationsBadgeReset,
    notificationDismiss,
    notificationAction,
    markMessageReadA,
    markMessageReadB,
    createOrder,
    createQuotation,
    updatePaymentOverview,
    createPaymentOverview,
    createHandoverOverview,
    updateHandoverOverview,
    createDemoLog,
    updateDemoLog,
    generateContract,
    updateOrderStatus,
  };

  const _deleteAPIs: DELETE_APIS = {
    deleteEntity,
  };

  return {
    _apis,
    _updateAPIs,
    _deleteAPIs,
  };
};
export default useEndpoints;
